<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">结算单附件列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel" >行政区划:</span>
              <el-cascader
                clearable
                filterable
                v-model="searchData.areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
             <div title="结算单号" class="searchboxItem ci-full">
              <span class="itemLabel">结算单号:</span>
              <el-input
                v-model="searchData.billNo"
                clearable
                placeholder="请输入结算单号"
                size="small"
              ></el-input>
            </div>
             <div title="结算单状态" class="searchboxItem ci-full">
              <span class="itemLabel"
                >结算单状态:</span
              >
               <el-select
            size="small"
            v-model="searchData.billState"
            placeholder="请选择结算单状态"
            clearable
          >
            <el-option
              v-for="item in settlementStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
            </div>
            <div title="结算单类型" class="searchboxItem ci-full">
              <span class="itemLabel"
              >结算单类型:</span
              >
              <el-select
                  size="small"
                  v-model="searchData.billType"
                  placeholder="请选择结算单类型"
                  clearable
              >
                <el-option
                    v-for="item in billTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <!-- <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:10rem">机构名称:</span>
              <el-select
                size="small"
                v-model="searchData.signCompId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div> -->
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                style="width:100%;"
                size="small"
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                v-model="searchData.trainTypeId"
                :options="trainTypeList"
                clearable
                @change="handleTrainType"
              ></el-cascader>
            </div>
            <div title="附件类型" class="searchboxItem ci-full">
              <span class="itemLabel">附件类型:</span
              >
              <el-select
                size="small"
                v-model="searchData.docType"
                clearable
                placeholder="请选择附件类型"
              >
                <el-option
                  v-for="item in docTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="收款结算单到账状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:10rem"
                >收款结算单到账状态:</span
              >
              <el-select
                size="small"
                v-model="searchData.whetherAccount"
                clearable
                placeholder="请选择收款结算单到账状态"
              >
                <el-option
                  v-for="item in settlementArrivedList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="上传时间" class="searchboxItem ci-full">
              <span class="itemLabel">上传时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.fileCreateTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="结算单创建时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7.5rem">结算单创建时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.billCreateTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df" style="padding-left:10px">
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button type="primary" class="bgc-bv" round @click="doExport()"
              >导出</el-button
            >
             </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="结算单号"
                align="left"
                show-overflow-tooltip
                prop="billNo"
                min-width="180px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="220px"
              />
              <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="settlementUserCount"
              />
              <el-table-column
                label="结算金额"
                align="left"
                show-overflow-tooltip
                prop="payerAmount"
              />
              <el-table-column
                label="结算单状态"
                align="left"
                show-overflow-tooltip
                prop="auditType"
                min-width="120px"
              >
              <template slot-scope="scope">
                  {{$setDictionary('FD_AUDIT_TYPE',scope.row.auditType)}}
              </template>
              </el-table-column>
              <el-table-column
                label="代理商名称"
                align="left"
                show-overflow-tooltip
                prop="agencyName"
                min-width="160px"
              />
              <el-table-column
                  label="结算单类型"
                  align="left"
                  show-overflow-tooltip
                  prop="billType"
                  width="150"
              >
                <template slot-scope="scope">
                  {{$setDictionary('FD_BILL_TYPE',scope.row.billType)}}
                </template>
              </el-table-column>
              <el-table-column
                label="结算单创建日期"
                align="left"
                show-overflow-tooltip
                prop="billCreateTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.billCreateTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="附件类型"
                align="left"
                show-overflow-tooltip
                prop="docType"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.docType == '10' ? '结算单照片' : scope.row.docType == '20' ? '结算单原单' : scope.row.docType == '99' ? '其他':'--' }}
                </template>
              </el-table-column>
              <el-table-column
                label="上传人"
                align="left"
                show-overflow-tooltip
                prop="fullName"
              />
              <el-table-column
                label="上传时间"
                align="left"
                show-overflow-tooltip
                fixed="right"
                prop="fileCreateTime"
                min-width="150px"
              >
              <template slot-scope="scope">
                  {{ scope.row.fileCreateTime | moment }}
              </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //查询条件
      searchData: {
        areaId: "",
         billNo:'',
        fileCreateTime: "",
        billCreateTime: "",
        trainTypeId: "",
        billState: "",
        billType: "",
        whetherAccount: "",
      },
        CompanyList: [],
      trainTypeList: [],
      settlementStatusList: [
      ],
      billTypeList: [
        {
          label: "收款结算单",
          value: '10',
        },
        {
          label: "付款结算单",
          value: '20',
        },
        {
          label: "代理结算单",
          value: '30',
        }
      ],
      settlementArrivedList: [
        {
          label: "已到账",
          value: true,
        },
        {
          label: "未到账",
          value: false,
        },
        // {
        //   label: "部分到账",
        //   value: "15",
        // },
      ],
      docTypeList:[
         {
          label: "结算单照片",
          value: '10',
        },
        {
          label: "结算单原单",
          value: '20',
        },
        {
          label: "其他",
          value: '99',
        },
      ],
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.getStatusList();
    this.getTraintype();
  },
  methods: {
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
     getStatusList() {
      const statusList = this.$setDictionary("FD_AUDIT_TYPE", "list");
      const list = [];
      for (const key in statusList) {
          list.push({
            value: key,
            label: statusList[key],
          });
      }
      this.settlementStatusList = list;
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        whetherAccount:this.searchData.whetherAccount
      };

      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.billState) {
        params.auditType = this.searchData.billState;
      }
      if (this.searchData.billType) {
        params.billType = this.searchData.billType;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.billCreateTime) {
        params.billCreateStartTime = this.searchData.billCreateTime[0];
        params.billCreateEndTime = this.searchData.billCreateTime[1];
      }
      if (this.searchData.fileCreateTime) {
        params.fileCreateStartTime = this.searchData.fileCreateTime[0];
        params.fileCreateEndTime = this.searchData.fileCreateTime[1];
      }
      if (this.searchData.docType) {
        params.docType = this.searchData.docType;
      }
      this.doFetch({
        url: "/biz/new/bill/file/pageList",
        params,
        pageNum,
      });
    },
    doExport() {
       const params = {
           whetherAccount:this.searchData.whetherAccount
      };

      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.billState) {
        params.auditType = this.searchData.billState;
      }
      if (this.searchData.billType) {
        params.billType = this.searchData.billType;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.billCreateTime) {
        params.billCreateStartTime = this.searchData.billCreateTime[0];
        params.billCreateEndTime = this.searchData.billCreateTime[1];
      }
      if (this.searchData.fileCreateTime) {
        params.fileCreateStartTime = this.searchData.fileCreateTime[0];
        params.fileCreateEndTime = this.searchData.fileCreateTime[1];
      }
      if (this.searchData.docType) {
        params.docType = this.searchData.docType;
      }
      this.$post("/biz/new/bill/file/export",params)
        .then((res) => {
           if (res.status == "0") {
               window.open(res.message)
              }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 7) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function(route) {
        if (route.query.refresh == true) {
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.itemLabel {
  min-width: 5rem;
}
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.el-textarea__inner {
  min-height: 7.5rem !important;
  resize: none;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.dialog-footer {
  margin-top:.75rem;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less">
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
